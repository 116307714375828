import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import InspectionCountTable from '../InspectionCountTable';
import InspectionTable from '../InspectionTable';
import InspectionEditorTable from '../InspectionEditorTable';
import InspectionViewerTable from '../InspectionViewerTable';
import { useAuthenticated } from 'react-admin';
import DatePickerComponent from './DatePicker';
import SalesChartComponent from './SalesChartComponent';
import VisitorsChartComponent from './VisitorsChartComponent';
import dotenv from 'dotenv';

dotenv.config();

export default function ContentEditor({ selectedMenu }) {
    useAuthenticated();
    const [tenant, setTenant] = useState('');
    const [id, setId] = useState('');
    const [rolid, setRolId] = useState('');
    const [rol, setRol] = useState('');
    const [isLoading, setIsLoading] = useState(false); 
    const [selectedStatus, setSelectedStatus] = useState('');
    const [inspections, setInspections] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalSales, setTotalSales] = useState(0);
    const [salesIncrease, setSalesIncrease] = useState('0%');
    const [transcursoDeVisitas, setTranscursoDeVisitas] = useState(0);
    const [percentageChange, setPercentageChange] = useState('0%');    

    const [counts, setCounts] = useState({
        completed: 0,
        pending: 0,
        canceled: 0,
        failed: 0
    });
    const [labels, setLabels] = useState(Array(12).fill(''));

    const [salesData1, setSalesData1] = useState(Array(12).fill(0));

    const [salesData2, setSalesData2] = useState(Array(12).fill(0));

    const [sesionlabels, setSessionLabels] = useState(Array(12).fill(''));

    const [sesionData1, setSessionData1] = useState(Array(12).fill(0));

    const [sesionData2, setSessionData2] = useState(Array(12).fill(0));
    

    const [tableLoaded, setTableLoaded] = useState(false);


    const handleClearFilters = () => {
        setSelectedStatus('');
        setStartDate('');
        setEndDate('');
        // Llama a la función de actualización o filtrado aquí si necesitas actualizar la tabla de servicios.
    };
 
    const apiAtransactionUrl = process.env.REACT_APP_API_TRANSACTION_URL;
    const apiSessionUrl = process.env.REACT_APP_API_SESSION_URL;

    // Fetch the data only once when tenant, id, and rol are set
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            try {
                const userObject = JSON.parse(storedUser);
                setTenant(userObject.cliente?.tenant || '');
                setId(userObject.cliente?.id || '');
                setRolId(userObject.rol?.id || '');
                setRol(userObject.rol?.nombre || '');
            } catch (error) {
                console.error('Error parsing user from localStorage:', error);
            }
        }
    }, []);

    // Fetch data when tenant, id, or rol changes
    useEffect(() => {
        setIsLoading(true);
        if (tenant && id && rolid) {
            handleTransactionAdmin();
            loadSessions();
        }
    }, [selectedStatus, startDate, endDate, tenant, id, rolid]);


    const handleTransactionAdmin = async () => {
        try {
            const requestData = {
                tenant: tenant,
                id: id,
                rol: rolid
            };

            const response = await axios.post(apiAtransactionUrl, requestData, {
                /* withCredentials: false, */
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setInspections(response.data); // Save data to state
            updateSalesData(response.data);

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // Desactiva el estado de carga una vez que termina
        }
    };


    const loadSessions = async () => {
        try {
            const requestData = {
                usuario_id: 'usuario_especifico' // Cambia por el ID de usuario necesario
            };
    
            const response = await axios.post(apiSessionUrl, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            // Asigna los datos de la API a los estados correspondientes
            setSessionData1(response.data.thisWeek); // Datos de esta semana
            setSessionData2(response.data.lastWeek); // Datos de la semana pasada
            setSessionLabels(response.data.labels);  // Etiquetas de los días


            // Calculate total visits for this week and last week
            const totalThisWeek = response.data.thisWeek.reduce((acc, visits) => acc + visits, 0);
            const totalLastWeek = response.data.lastWeek.reduce((acc, visits) => acc + visits, 0);

            // Set the "Transcurso de Visitas" value
            setTranscursoDeVisitas(totalThisWeek);

            // Calculate and set the percentage change
            let change = '0%';
            if (totalLastWeek !== 0) {
                change = ((totalThisWeek - totalLastWeek) / totalLastWeek * 100).toFixed(2) + '%';
            } else if (totalThisWeek > 0) {
                change = '100%'; // If no visits last week but there are visits this week
            }
            setPercentageChange(change);
    
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const updateSalesData = (data) => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const lastYear = currentYear - 1;

        let currentYearTotals = Array(12).fill(0);
        let lastYearTotals = Array(12).fill(0);
        let totalSum = 0;

        // Filtrar los datos por estado y fecha
        const filteredData = data.filter(inspection => {
            const transactionDate = new Date(inspection.fecha);
            const isStatusMatch = selectedStatus === '' || inspection.status.toLowerCase() === selectedStatus.toLowerCase();
            const isDateInRange = (!startDate || transactionDate >= subtractOneDay(startDate)) &&
                (!endDate || transactionDate <= new Date(endDate));

            return isStatusMatch && isDateInRange;
        });

        if (filteredData.length === 0) {
            setSalesData1(Array(12).fill(0));
            setSalesData2(Array(12).fill(0));
            setTableLoaded(false);
            return;
        }

        filteredData.forEach(item => {
            const transactionDate = new Date(item.fecha);
            const month = transactionDate.getMonth();
            const year = transactionDate.getFullYear();
            const totalValue = parseFloat(item.total) || 0;

            if (year === currentYear) {
                currentYearTotals[month] += totalValue;
                totalSum += totalValue;
                setTotalSales(totalSum);
            } else if (year === lastYear) {
                lastYearTotals[month] += totalValue;
            }
        });


        // Calcular el incremento o decremento para el mes actual
        const currentMonthSalesThisYear = currentYearTotals[currentMonth];
        const currentMonthSalesLastYear = lastYearTotals[currentMonth];
        
        // Contadores para las cajas
        const counters = {
            completed: filteredData.filter(item => item.status === 'Completadas').length,
            pending: filteredData.filter(item => item.status === 'Pendientes').length,
            canceled: filteredData.filter(item => item.status === 'Canceladas').length,
            failed: filteredData.filter(item => item.status === 'Fallidas').length
        };
        setCounts(counters);


        if (currentMonthSalesLastYear !== 0) {
           let salesIncrease = Math.round(((currentMonthSalesThisYear - currentMonthSalesLastYear) / currentMonthSalesLastYear * 100).toFixed(2)) + '%';
            setSalesIncrease(salesIncrease);
        } else if (currentMonthSalesThisYear > 0) {
            let salesIncrease = '100%'; // Si el año pasado fue 0 y este año hay ventas, el aumento es del 100%.
            setSalesIncrease(salesIncrease);
        }

        // Lógica para calcular `currentYearTotals` y `lastYearTotals`
        setLabels(['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']);
        setSalesData1(currentYearTotals);
        setSalesData2(lastYearTotals);
        setTableLoaded(true);

    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value); // Esto desencadenará el efecto para actualizar los datos
    };

    const subtractOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
    };

    const filteredInspections = useMemo(() => {
        return inspections.filter(inspection => {
            const transactionDate = new Date(inspection.fecha);
            const isStatusMatch = selectedStatus === '' || inspection.status.toLowerCase() === selectedStatus.toLowerCase();
            const isDateInRange = (!startDate || transactionDate >= subtractOneDay(startDate)) &&
                (!endDate || transactionDate <= new Date(endDate));

            return isStatusMatch && isDateInRange;
        });
    }, [inspections, selectedStatus, startDate, endDate]);

    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.completed} /></h3>
                                    <p>Completadas</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-android-done"></i>
                                </div>
                                {/* <a href="/" className="small-box-footer" onClick={() => onMenu('Principal')}>Mas info <i className="fas fa-arrow-circle-right"></i></a> */}
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.pending} /></h3>
                                    <p>Pendientes</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-alert-circled"></i>
                                </div>
                                {/* <a href="/" className="small-box-footer" onClick={() => onMenu('Pending')}>Mas info<i className="fas fa-arrow-circle-right"></i></a> */}
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-gray">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.canceled} /></h3>
                                    <p>Canceladas</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-android-delete"></i>
                                </div>
                                {/* <a href="/" className="small-box-footer" onClick={() => onMenu('Canceled')}>Mas info <i className="fas fa-arrow-circle-right"></i></a> */}
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.failed} /></h3>
                                    <p>Fallidas</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-close-round"></i>
                                </div>
                                {/* <a href="/" className="small-box-footer" onClick={() => onMenu('Failed')}>Mas info <i className="fas fa-arrow-circle-right"></i></a> */}
                            </div>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header border-0">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title">Visitas en linea</h3>
                                        <a href="javascript:void(0);">Ver Reporte</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                            <p className="d-flex flex-column">
                                                <span className="text-bold text-lg">{transcursoDeVisitas}</span>
                                                <span>Transcurso de Visitas</span>
                                            </p>
                                            <p className="ml-auto d-flex flex-column text-right">
                                                <span className="text-success">
                                                    <i className="fas fa-arrow-up" /> {percentageChange}
                                                </span>
                                                <span className="text-muted">Desde Última Semana...</span>
                                            </p>
                                        </div>
                                        {isLoading ? (
                                            <p>Loading...</p> // Muestra el texto "Loading..." mientras se cargan los datos
                                        ) : tableLoaded && (sesionData1.some(data => data > 0) || sesionData2.some(data => data > 0)) ? (
                                            <VisitorsChartComponent newlabels={sesionlabels} newdataset1={sesionData1} newdataset2={sesionData2} />
                                        ) : (
                                            <p>No hay datos disponibles para mostrar el gráfico.</p>
                                        )} 
                                    <div className="d-flex flex-row justify-content-end">
                                        <span className="mr-2">
                                            <i className="fas fa-square text-primary" /> Esta Semana
                                        </span>
                                        <span>
                                            <i className="fas fa-square text-gray" /> Última Semana
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header border-0">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title">Ventas</h3>
                                        <a href="javascript:void(0);">Ver Reporte</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <p className="d-flex flex-column">
                                            <span className="text-bold text-lg">{isLoading === false ? `$ ${totalSales.toLocaleString('es-CL')}` : '$ 0'}</span>
                                            <span>Ventas transcurridas</span>
                                        </p>
                                        <p className="ml-auto d-flex flex-column text-right">
                                            <span className="text-success">
                                                <i className="fas fa-arrow-up" /> {isLoading === false ? salesIncrease : '0 %'}
                                            </span>
                                            <span className="text-muted">Desde el último Mes</span>
                                        </p>
                                    </div>
                                    <div className="position-relative mb-4">
                                        {isLoading ? (
                                            <p>Loading...</p> // Muestra el texto "Loading..." mientras se cargan los datos
                                        ) : tableLoaded && (salesData1.some(data => data > 0) || salesData2.some(data => data > 0)) ? (
                                            <SalesChartComponent labels={labels} salesData1={salesData1} salesData2={salesData2} />
                                        ) : (
                                            <p>No hay datos disponibles para mostrar el gráfico.</p>
                                        )}
                                    </div>

                                    <div className="d-flex flex-row justify-content-end">
                                        <span className="mr-2">
                                            <i className="fas fa-square text-primary" /> Este Año
                                        </span>
                                        <span>
                                            <i className="fas fa-square text-gray" /> Último Año
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="row align-items-center mb-3">
                        {/* Filtro de estado */}
                        <div className="col-md-6 d-flex align-items-center">
                            <label htmlFor="statusFilter" className="mr-2">Filtrar por estado:</label>
                            <select
                                id="statusFilter"
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                className="form-control w-auto"
                            >
                                <option value="">Todos</option>
                                <option value="Completadas">Completadas</option>
                                <option value="Pendientes">Pendientes</option>
                                <option value="Canceladas">Canceladas</option>
                                <option value="Fallidas">Fallidas</option>
                            </select>
                            <button onClick={handleClearFilters} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                <i className="fas fa-trash" style={{ color: '#007bff' }}></i>
                            </button>


                        </div>

                        {/* Filtro de rango de fechas */}
                        <div className="col-md-6 d-flex justify-content-end">
                            <DatePickerComponent
                                startDate={startDate}
                                setStartDate={(date) => {
                                    setStartDate(date);
                                    updateSalesData(inspections); // Ejecuta actualización después de cambiar la fecha de inicio
                                }}
                                endDate={endDate}
                                setEndDate={(date) => {
                                    setEndDate(date);
                                    updateSalesData(inspections); // Ejecuta actualización después de cambiar la fecha de fin
                                }}
                            />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            {rol === 'admin' ? (
                                <InspectionTable inspections={filteredInspections} />
                            ) : rol === 'editor' ? (
                                <InspectionEditorTable inspections={filteredInspections} />
                            ) : (
                                <InspectionViewerTable inspections={filteredInspections} />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
