import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table'; 
import './InspectionTable.css';



function InspectionTable({ inspections, setInspections }) {

  // Define las columnas para react-table
  const columns = React.useMemo(
    () => [
     // { Header: 'Entidad', accessor: 'entidad' }, 
     { 
      Header: 'N°', 
      Cell: (row) => {
        return row.row.index + 1; // Devuelve el número de la fila
      },
      disableSortBy: true // Desactiva el ordenamiento en esta columna
    }, 
      // { Header: 'ID', accessor: 'id' }, 
      { Header: 'Tenant', accessor: 'tenant_name' }, 
      { Header: 'Cliente', accessor: 'client_name' }, 
      { Header: 'Servicio', accessor: 'service_name' },    
      { Header: 'Producto', accessor: 'producto_name' },    
      { Header: 'Estado', accessor: 'status' },  
      { Header: 'Fecha', accessor: 'fechaformated' },  
      { Header: 'Hora', accessor: 'hora' },  
      { 
        Header: 'Tarifa', 
        accessor: 'tarifa',
        Cell: ({ value }) => parseFloat(value).toLocaleString('es-CL') // Formato de miles
      },    
      { 
        Header: 'TDS', 
        accessor: 'tds',
        Cell: ({ value }) => parseFloat(value).toLocaleString('es-CL') // Formato de miles
      },    
      { 
        Header: 'Total', 
        accessor: 'total',
        Cell: ({ value }) => parseFloat(value).toLocaleString('es-CL') // Formato de miles
      } 
    ],
    []
  );

  // Calcula la cantidad total de registros
  const totalRecords = inspections.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize }
  } = useTable(
    { columns, data: inspections, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );
 

  // Componente de tabla con paginación y ordenamiento
  return (
    <div className="card">
      <div className="card-header border-0">
        <h3 className="card-title">Consumo de Servicios</h3>
        <div className="card-tools">
          <span>Total de Registros: {totalRecords}</span>
          <a href="/" className="btn btn-tool btn-sm">
            <i className="fas fa-download" />
          </a>
          <a href="/" className="btn btn-tool btn-sm">
            <i className="fas fa-bars" />
          </a>
        </div>
      </div>
      <div >
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td  {...cell.getCellProps()}>
                    {cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <div>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Anterior
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Siguiente
        </button>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20, 30, 40, 50].map(size => (
            <option key={size} value={size}>
              Mostrar {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default InspectionTable;
