import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticated } from 'react-admin';

const MyLoginPage = ({ onLogin, onLogout }) => {
    useAuthenticated(); // Verifica si la sesión está autenticada
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            console.log('Token detectado en localStorage.');
            setIsLoggedIn(true);
        }
        setLoading(false); // Finaliza la carga
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            console.log('Usuario autenticado, navegando a la página principal.');
            navigate('/'); // Redirige solo si el usuario está autenticado
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const jwtToken = queryParams.get('token') || localStorage.getItem('token');
        const user = queryParams.get('user') || localStorage.getItem('user');

        if (jwtToken) {
            console.log('Token encontrado. Guardando en localStorage.');
            localStorage.setItem('token', jwtToken);
            localStorage.setItem('user', user);
            setIsLoggedIn(true); // Actualiza el estado
            onLogin && onLogin(); // Ejecuta `onLogin` si está definido
        }
    }, [onLogin]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div>
            {isLoggedIn ? (
                <p>Sesión iniciada. Redirigiendo...</p>
            ) : (
                <p>No has iniciado sesión. Por favor, inicia sesión.</p>
            )}
        </div>
    );
};

export default MyLoginPage;
