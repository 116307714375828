import React from 'react';

export default function Footer() {
    // Acceder a la versión desde la variable de entorno
    const version = process.env.REACT_APP_VERSION || 'unknown';

    return (
        <footer className="main-footer">
        <strong>Copyright © 2024-2025 <span>MyCreditScore</span>.</strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> {version}
        </div>
    </footer>
    );
}
