import React from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
//import { format } from 'date-fns'; // Para formatear las fechas correctamente

export default function DatePickerComponent({ startDate, setStartDate, endDate, setEndDate }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="date-picker-container">
                <DatePicker
                    label="Desde"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    inputFormat="dd-MM-yyyy"  // Formato de fecha
                    renderInput={(params) => (  
                        <TextField {...params} onKeyDown={(e) => e.preventDefault()} // Deshabilita la entrada de texto manual
                        />)}
                />
                <DatePicker
                    label="Hasta"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)} // Se invoca el filtro en Content.js
                    inputFormat="dd-MM-yyyy"  // Formato de fecha
                    renderInput={(params) => (  
                        <TextField {...params} onKeyDown={(e) => e.preventDefault()} // Deshabilita la entrada de texto manual
                        />)}
                />
            </div>
        </LocalizationProvider>
    );
}
