import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './components/Header';
import Aside from './components/Aside';
import Content from './components/Content';
import ContentEditor from './components/ContentEditor';
import ContentViewer from './components/ContentViewer';
import Footer from './components/Footer';
import MyLoginPage from './pages/MyLoginPage';

const Home = () => (
    <div>
        <h1>Welcome to Home</h1>
        <p>This is the home page content.</p>
    </div>
);

const queryClient = new QueryClient();

function App() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEditor, setIsEditor] = useState(false);
    const [isViewer, setIsViewer] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
    const [selectedMenu, setSelectedMenu] = useState(localStorage.getItem('selectedMenu') || 'Principal');

    const handleLogin = useCallback(() => {
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        setSelectedMenu("Principal");
    }, []);

    const handleLogout = useCallback(() => {
        setIsLoggedIn(false);
        setUser({});
        localStorage.clear();
        window.location.href = 'https://mycreditscore.cl';
    }, []);

    const handleMenuSelection = useCallback((menu) => {
        setSelectedMenu(menu);
        localStorage.setItem('selectedMenu', menu);
    }, []);

    useEffect(() => {
        // Check roles when `user` changes
        if (user && user.rol) {
            setIsAdmin(user.rol.nombre === 'admin');
            setIsEditor(user.rol.nombre === 'editor');
            setIsViewer(user.rol.nombre === 'viewer');
        }
    }, [user]);

    useEffect(() => {
        if (isLoggedIn) {
            localStorage.setItem('isLoggedIn', 'true');
        } else {
            localStorage.removeItem('isLoggedIn');
        }
    }, [isLoggedIn]);

    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setUser(JSON.parse(savedUser));
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<MyLoginPage onLogin={handleLogin} />} />
                        <Route
                            path="/"
                            element={
                                isLoggedIn ? (
                                    <>
                                        <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} selectedMenu={selectedMenu} />
                                        <Aside onMenu={handleMenuSelection} selectedMenu={selectedMenu} />
                                        {selectedMenu === 'Principal' && (
                                            <>
                                                {isAdmin && <Content selectedMenu={selectedMenu} />}
                                                {isEditor && <ContentEditor selectedMenu={selectedMenu} />}
                                                {isViewer && <ContentViewer selectedMenu={selectedMenu} />}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                        <Route path="/home" element={<Home />} />
                    </Routes>
                    <Footer />
                </div>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
