import React, { useState, useEffect, memo } from 'react';


// Custom hook para obtener datos del usuario
const useUserData = () => {
    const [userData, setUserData] = useState({
        name: '',
        rol: '',
        client: ''
    });

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        
        console.log(user); // Agrega esto para verificar qué datos se recuperan
    
        if (user?.cliente && user?.rol) {
            setUserData({
                name: `${user.cliente.nombre} ${user.cliente.apellido}`,
                rol: user.rol.nombre.toUpperCase(),
                client: `${user.cliente.nombre} ${user.cliente.apellido}`
            });
        }
    }, []);

    return userData;
};
 

const Aside = ({ onMenu, selectedMenu }) => {
    const { client } = useUserData();

    const [menu, setMenu] = useState(selectedMenu);

    useEffect(() => {
        setMenu(selectedMenu); // Sincroniza con selectedMenu del padre
    }, [selectedMenu]);

    const handleMenuClick = (menuName) => {
        setMenu(menuName);
        onMenu(menuName); // Notificar al componente padre
    };

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Logo */}
            <a href="/" className="brand-link">
                <img
                    src="dist/img/logo.png"
                    alt="AdminSMI Logo"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: '.8' }}
                />
                <span className="brand-text font-weight-light">MyCreditscore</span>
            </a>

            {/* Sidebar */}
            <div className="sidebar">
                {/* User Panel */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src="dist/img/user-icon.png"
                            className="img-circle elevation-2"
                            alt={client}
                        />
                    </div>
                    <div className="info">
                        <a href="/" className="d-block">{client}</a>
                        <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
                    </div>
                </div>

                {/* Search Form */}
                {/* <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input
                            className="form-control form-control-sidebar"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                        />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw" />
                            </button>
                        </div>
                    </div>
                </div> */}

                {/* Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item menu-open">
                            <a href="/" className="nav-link active">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>
                                    Menu
                                    <i className="right fas fa-angle-left" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <a
                                        href="/"
                                        className={`nav-link ${selectedMenu === 'Principal' ? 'active' : ''}`}
                                        onClick={(e) => { e.preventDefault(); handleMenuClick('Principal') }}
                                    >
                                        <i className="far fa-circle nav-icon" />
                                        <p>Principal</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default memo(Aside);
