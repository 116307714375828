import React, { useEffect } from 'react';

const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (existingScript) {
            resolve();
        } else {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                console.log(`Script ${src} cargado exitosamente.`);
                resolve(true);
            };
            script.onerror = () => {
                console.error(`Error al cargar el script ${src}.`);
                reject(false);
            };
            document.body.appendChild(script);
        }
    });
};


const SalesChartComponent = ({ labels, salesData1, salesData2 }) => {


    
    useEffect(() => {
        const initializeChart = async () => {
            try {
                await loadScript('dist/js/pages/dashboard3.js');
                const hasNonZeroData1 = salesData1 && salesData1.some(value => value !== 0);
                const hasNonZeroData2 = salesData2 && salesData2.some(value => value !== 0);
                
                const interval = setInterval(() => {
                    if (typeof window.updateSalesChart === 'function') {
                        clearInterval(interval);  // Clear interval once the function is available
                        if (hasNonZeroData1 || hasNonZeroData2) {

                            if (window.salesChart) {
                                window.salesChart.destroy();
                            }

                            window.updateSalesChart(labels, salesData1, salesData2);
                        }
                    } else {
                        console.warn('Esperando que la función updateSalesChart esté disponible en window...');
                    }
                }, 100); // Check every 100ms until the function is available
            } catch (error) {
                console.error('Error al cargar el script dashboard3.js:', error);
            }
        };
        initializeChart();
    }, [labels, salesData1, salesData2]);

    return (
        <div className="position-relative mb-4">
            <canvas id="sales-chart" height={200} />
        </div>
    );
};

export default SalesChartComponent;
